import React, { useState, useCallback, useRef, useEffect } from "react";
import {
  Box,
  VStack,
  Text,
  Progress,
  SimpleGrid,
  Image,
  AspectRatio,
  Icon,
  IconButton,
  Spinner,
} from "@chakra-ui/react";
import { Upload, X } from "lucide-react";
import { uploadMedia } from "../services/media";
import { Media } from "../types/media";
import axios from "axios";

interface MediaUploaderProps {
  albumId: string;
  onUploadComplete: (newMedia: Media[]) => void;
}

interface UploadStatus {
  file: File;
  progress: number;
  status: "waiting" | "uploading" | "success" | "error";
  error?: string;
  uploadedAt?: number;
}

const MediaUploader: React.FC<MediaUploaderProps> = ({
  albumId,
  onUploadComplete,
}) => {
  const [uploadStatuses, setUploadStatuses] = useState<UploadStatus[]>([]);
  const [isDragging, setIsDragging] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [isUploading, setIsUploading] = useState(false);
  const [isAllComplete, setIsAllComplete] = useState(false);

  // Effect to clean up successful uploads after delay
  useEffect(() => {
    const cleanup = setInterval(() => {
      const now = Date.now();
      setUploadStatuses((prev) =>
        prev.filter(
          (status) =>
            status.status !== "success" ||
            !status.uploadedAt ||
            now - status.uploadedAt < 3000
        )
      );
    }, 1000);

    return () => clearInterval(cleanup);
  }, []);

  // Add effect to check for all uploads complete
  useEffect(() => {
    if (
      uploadStatuses.length > 0 &&
      uploadStatuses.every((status) => status.status === "success")
    ) {
      setIsAllComplete(true);
      // Reset the complete status after 5 seconds
      const timer = setTimeout(() => setIsAllComplete(false), 5000);
      return () => clearTimeout(timer);
    }
  }, [uploadStatuses]);

  // Move uploadFile before the effect that uses it
  const uploadFile = useCallback(
    async (file: File, index: number) => {
      try {
        const media = await uploadMedia(albumId, file, (progress) => {
          setUploadStatuses((prev) =>
            prev.map((status, i) =>
              i === index ? { ...status, progress } : status
            )
          );
        });

        setUploadStatuses((prev) =>
          prev.map((status, i) =>
            i === index
              ? {
                  ...status,
                  status: "success",
                  uploadedAt: Date.now(),
                }
              : status
          )
        );

        onUploadComplete([media]);
      } catch (error) {
        let errorMessage = "Upload failed";

        if (axios.isAxiosError(error) && error.response) {
          const responseData = error.response.data as { file?: string[] };
          const fileError = responseData?.file?.[0];
          if (fileError) {
            errorMessage = fileError;
          }
        } else if (error instanceof Error) {
          errorMessage = error.message;
        }

        setUploadStatuses((prev) =>
          prev.map((status, i) =>
            i === index
              ? { ...status, status: "error", error: errorMessage }
              : status
          )
        );
      }
    },
    [albumId, onUploadComplete]
  );

  // Now the effect can use uploadFile
  useEffect(() => {
    const processQueue = async () => {
      if (
        isUploading ||
        !uploadStatuses.some((status) => status.status === "waiting")
      ) {
        return;
      }

      setIsUploading(true);

      const nextUploadIndex = uploadStatuses.findIndex(
        (status) => status.status === "waiting"
      );
      if (nextUploadIndex === -1) {
        setIsUploading(false);
        return;
      }

      await new Promise((resolve) => setTimeout(resolve, 2000));
      await uploadFile(uploadStatuses[nextUploadIndex].file, nextUploadIndex);

      setIsUploading(false);
    };

    processQueue();
  }, [uploadStatuses, isUploading, uploadFile]);

  const handleFileChange = useCallback((files: FileList | null) => {
    if (files) {
      const filesArray = Array.from(files);
      const newStatuses: UploadStatus[] = filesArray.map((file) => ({
        file,
        progress: 0,
        status: "waiting",
      }));

      setUploadStatuses((prev) => [...prev, ...newStatuses]);
    }
  }, []);

  const handleDragEnter = useCallback((e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  }, []);

  const handleDragLeave = useCallback((e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  }, []);

  const handleDragOver = useCallback((e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  const handleDrop = useCallback(
    (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault();
      e.stopPropagation();
      setIsDragging(false);
      const files = e.dataTransfer.files;
      handleFileChange(files);
    },
    [handleFileChange]
  );

  const handleRemoveUpload = useCallback((index: number) => {
    setUploadStatuses((prev) => prev.filter((_, i) => i !== index));
  }, []);

  const renderPreview = useCallback((status: UploadStatus) => {
    return (
      <VStack height="100%" width="100%" spacing={2}>
        {/* Media Preview */}
        <Box flex="1" width="100%" overflow="hidden">
          {status.file.type.startsWith("image/") ? (
            <Image
              src={URL.createObjectURL(status.file)}
              alt={`Preview ${status.file.name}`}
              objectFit="cover"
              width="100%"
              height="100%"
            />
          ) : status.file.type.startsWith("video/") ? (
            <AspectRatio ratio={16 / 9}>
              <video controls>
                <source
                  src={URL.createObjectURL(status.file)}
                  type={status.file.type}
                />
                Your browser does not support the video tag.
              </video>
            </AspectRatio>
          ) : (
            <Text>Unsupported file type</Text>
          )}
        </Box>

        {/* Status Indicator */}
        {status.status === "waiting" && <Spinner size="sm" color="gray.400" />}
      </VStack>
    );
  }, []);

  return (
    <VStack spacing={4} align="stretch">
      {isAllComplete && (
        <Text color="green.500" textAlign="center" fontWeight="medium">
          Upload Complete! We're processing your media now and it will appear in
          the album shortly.
        </Text>
      )}
      <Box
        borderWidth={2}
        borderStyle="dashed"
        borderRadius="md"
        p={6}
        textAlign="center"
        bg={isDragging ? "gray.100" : "transparent"}
        transition="all 0.2s"
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        onClick={() => fileInputRef.current?.click()}
        cursor="pointer"
      >
        <input
          ref={fileInputRef}
          type="file"
          multiple
          accept="image/*,video/*"
          onChange={(e) => handleFileChange(e.target.files)}
          style={{ display: "none" }}
        />
        <Icon as={Upload} w={10} h={10} color="gray.400" mb={2} />
        <Text mb={4}>Drag and drop files here, or click to select files</Text>

        <VStack spacing={1} fontSize="sm" color="gray.500">
          <Text>Photos: JPEG, PNG, RAW (max 500MB)</Text>
          <Text>Videos: MP4, MOV, AVI (max 1GB, 60 sec)</Text>
        </VStack>
      </Box>
      <SimpleGrid columns={{ base: 2, md: 3, lg: 4 }} spacing={4}>
        {uploadStatuses.map((status, index) => (
          <Box
            key={index}
            borderWidth={1}
            borderRadius="md"
            overflow="hidden"
            position="relative"
          >
            <IconButton
              aria-label="Remove upload"
              icon={<X />}
              size="sm"
              position="absolute"
              top={1}
              right={1}
              onClick={() => handleRemoveUpload(index)}
              zIndex={1}
            />
            <Box height="150px">{renderPreview(status)}</Box>
            <Box p={2}>
              <Text fontSize="sm" isTruncated>
                {status.file.name}
              </Text>
              <Progress
                value={status.progress}
                size="sm"
                colorScheme={
                  status.status === "success"
                    ? "green"
                    : status.status === "error"
                    ? "red"
                    : status.status === "waiting"
                    ? "gray"
                    : "blue"
                }
              />
              <Text
                fontSize="xs"
                color={
                  status.status === "success"
                    ? "green.500"
                    : status.status === "error"
                    ? "red.500"
                    : status.status === "waiting"
                    ? "gray.500"
                    : "gray.500"
                }
              >
                {status.status === "success"
                  ? "Uploaded"
                  : status.status === "error"
                  ? status.error
                  : status.status === "waiting"
                  ? "Waiting..."
                  : `${status.progress}%`}
              </Text>
            </Box>
          </Box>
        ))}
      </SimpleGrid>
    </VStack>
  );
};

export default MediaUploader;
