import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Box, Heading, VStack, Text, useToast } from "@chakra-ui/react";
import MediaSearchFilter, {
  FilterValues,
} from "../../components/MediaSearchFilter";
import MediaGrid from "../../components/MediaGrid";
import { searchMedia } from "../../services/media";
import { Media } from "../../types/media";
import { format } from "date-fns";

const MediaSearchPage: React.FC = () => {
  const [media, setMedia] = useState<Media[]>([]);
  const [loading, setLoading] = useState(false);
  const [hasSearched, setHasSearched] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const toast = useToast();

  const parseUrlParams = (): FilterValues | null => {
    const searchParams = new URLSearchParams(location.search);
    const surfBreakId = searchParams.get("surfBreakId") || "";
    const date = searchParams.get("date") || "";
    const startTime = searchParams.get("startTime") || "";
    const endTime = searchParams.get("endTime") || "";

    if (surfBreakId && date && startTime && endTime) {
      return { surfBreakId, date, startTime, endTime };
    }
    return null;
  };

  const initialFilters = parseUrlParams();

  const handleFilter = (filters: FilterValues) => {
    const searchParams = new URLSearchParams();
    Object.entries(filters).forEach(([key, value]) => {
      if (value) searchParams.append(key, value);
    });
    navigate(`/media?${searchParams.toString()}`);
  };

  const handleMediaUpdate = (updatedMedia: Media) => {
    setMedia((prevList) =>
      prevList.map((item) =>
        item.id === updatedMedia.id ? updatedMedia : item
      )
    );
  };

  useEffect(() => {
    const fetchMedia = async () => {
      const filters = parseUrlParams();
      if (!filters) {
        setHasSearched(false);
        return;
      }

      setLoading(true);
      setHasSearched(true);
      try {
        // Convert date and time strings to datetime objects
        const startDateTime = new Date(`${filters.date}T${filters.startTime}`);
        const endDateTime = new Date(`${filters.date}T${filters.endTime}`);

        // If end time is earlier than start time, assume it's the next day
        if (endDateTime < startDateTime) {
          endDateTime.setDate(endDateTime.getDate() + 1);
        }

        const formatToISO = (date: Date) =>
          format(date, "yyyy-MM-dd'T'HH:mm:ss");

        const searchParams = {
          surfBreakId: filters.surfBreakId,
          startDateTime: formatToISO(startDateTime),
          endDateTime: formatToISO(endDateTime),
        };

        const result = await searchMedia(searchParams);
        setMedia(result);
      } catch (error) {
        console.error("Error fetching media:", error);
        toast({
          title: "Error",
          description: "Failed to fetch media. Please try again.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      } finally {
        setLoading(false);
      }
    };

    fetchMedia();
  }, [location.search, toast]);

  return (
    <Box p={4}>
      <VStack spacing={8} align="stretch">
        <MediaSearchFilter
          onFilter={handleFilter}
          initialValues={initialFilters || undefined}
        />
        {loading ? (
          <Text textAlign="center">Loading media...</Text>
        ) : hasSearched && media.length === 0 ? (
          <Box textAlign="center" p={4}>
            <Text>
              No media available for this spot and time. Try another search!
            </Text>
          </Box>
        ) : (
          <MediaGrid media={media} onMediaUpdate={handleMediaUpdate} />
        )}
      </VStack>
    </Box>
  );
};

export default MediaSearchPage;
