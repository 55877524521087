import React, { useRef } from "react";
import {
  Box,
  Heading,
  Button,
  VStack,
  Flex,
  Container,
  Text,
  Image,
  SimpleGrid,
  Icon,
  Stat,
  StatNumber,
  StatLabel,
  Avatar,
  Stack,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import { FaCamera, FaDownload, FaUsers, FaWaveSquare } from "react-icons/fa";
import { IconType } from "react-icons";
import SurferLandingPage from "./SurferLandingPage";
import PhotographerLandingPage from "./PhotographerLandingPage";

interface FeatureCardProps {
  title: string;
  description: string;
  bgImage: string;
}

const FeatureCard: React.FC<FeatureCardProps> = ({
  title,
  description,
  bgImage,
}) => (
  <Box
    position="relative"
    height="500px"
    borderRadius="lg"
    overflow="hidden"
    transition="all 0.5s ease"
    _hover={{
      transform: "translateY(-8px)",
      "& > div:last-child": {
        bg: "rgba(0, 0, 0, 0.2)",
      },
      "& img": {
        transform: "scale(1.05)",
      },
    }}
  >
    <Box
      position="absolute"
      top={0}
      left={0}
      right={0}
      bottom={0}
      as="img"
      src={bgImage}
      transition="transform 0.5s ease"
      sx={{
        objectFit: "cover",
        width: "100%",
        height: "100%",
      }}
    />
    <Box
      position="absolute"
      top={0}
      left={0}
      right={0}
      bottom={0}
      bg="transparent"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="flex-end"
      p={10}
      textAlign="center"
      transition="all 0.3s ease"
    >
      <Heading
        size="2xl"
        mb={4}
        color="white"
        fontWeight="black"
        textTransform="lowercase"
        letterSpacing="-0.02em"
        textShadow="2px 2px 4px rgba(0,0,0,0.5)"
      >
        {title}
      </Heading>
      <Text
        color="white"
        fontSize="lg"
        fontStyle="italic"
        textShadow="1px 1px 2px rgba(0,0,0,0.5)"
      >
        {description}
      </Text>
    </Box>
  </Box>
);

const LandingPage: React.FC = () => {
  const surferRef = useRef<HTMLDivElement>(null);
  const photographerRef = useRef<HTMLDivElement>(null);

  const scrollToSection = (ref: React.RefObject<HTMLDivElement>) => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <Box bg="#F8F6F2">
      {/* Hero Section */}
      <Box position="relative" height="85vh" overflow="hidden">
        {/* Video Background */}
        <Box position="relative" height="100%" width="100%">
          <Box
            as="video"
            autoPlay
            loop
            muted
            playsInline
            poster="/landing_video_poster.jpg"
            sx={{
              position: "absolute",
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
          >
            <source src="/landing_video_720.mp4" type="video/mp4" />
          </Box>
          <Box
            position="absolute"
            top={0}
            left={0}
            right={0}
            bottom={0}
            bg="linear-gradient(to bottom, rgba(0,0,0,0.3), rgba(0,0,0,0.7))"
            zIndex={1}
          />
        </Box>

        {/* Hero Content */}
        <Flex
          position="absolute"
          top={0}
          left={0}
          right={0}
          bottom={0}
          zIndex={2}
          direction="column"
          align="center"
          justify="flex-start"
          textAlign="center"
          color="white"
          px={4}
          pt={20}
        >
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
          >
            <Image
              src="/logo.png"
              alt="Swelter Logo"
              width={{ base: "300px", md: "600px" }}
              height={{ base: "100px", md: "200px" }}
              mb={8}
              objectFit="contain"
            />
          </motion.div>
        </Flex>
      </Box>

      {/* Action Buttons Section */}
      <Box bg="#F8F6F2" py={16} px={4}>
        <Container maxW="container.xl">
          <Heading
            textAlign="center"
            fontSize={{ base: "2xl", md: "6xl" }}
            lineHeight="1.2"
            mb={12}
            fontWeight="bold"
            letterSpacing="-0.09em"
          >
            connecting everyday surfers
            <br />
            with freelance photographers
          </Heading>
          <Stack
            direction={{ base: "column", md: "row" }}
            spacing={4}
            justify="center"
            align="center"
          >
            <Button
              size="lg"
              colorScheme="accent"
              onClick={() => scrollToSection(surferRef)}
              px={8}
              py={6}
              fontSize="lg"
              bg="transparent"
              color="black"
              borderWidth="1px"
              borderColor="black"
              _hover={{
                bg: "black",
                color: "white",
                transform: "translateY(-4px)",
              }}
              transition="all 0.3s ease"
              fontWeight="normal"
              fontStyle="italic"
              borderRadius="full"
              width={{ base: "100%", md: "auto" }}
            >
              FIND YOUR PHOTOS
            </Button>
            <Button
              size="lg"
              onClick={() => scrollToSection(photographerRef)}
              px={8}
              py={6}
              fontSize="lg"
              bg="transparent"
              color="black"
              borderWidth="1px"
              borderColor="black"
              borderRadius="full"
              _hover={{
                bg: "black",
                color: "white",
                transform: "translateY(-4px)",
              }}
              transition="all 0.3s ease"
              fontWeight="normal"
              fontStyle="italic"
              width={{ base: "100%", md: "auto" }}
            >
              BECOME A PHOTOGRAPHER
            </Button>
          </Stack>
        </Container>
      </Box>

      {/* Surfer Landing Page */}
      <Container maxW="container.xl" px={4} pt={10}>
        <Box ref={surferRef}>
          <SurferLandingPage />
        </Box>
      </Container>

      {/* Features Section - Moved under Surfer Landing Page */}
      <Box py={20}>
        <Container maxW="container.xl">
          <VStack spacing={16}>
            <SimpleGrid
              columns={{ base: 1, md: 2, lg: 3 }}
              spacing={2}
              w="full"
            >
              <FeatureCard
                bgImage="/instant.png"
                title="instant downloads."
                description="get immediate access to high-resolution photos and videos"
              />
              <FeatureCard
                bgImage="/surfer-3.png"
                title="any break, any time."
                description="find photos from your favorite surf spots and sessions"
              />
              <FeatureCard
                bgImage="/surfer-1.png"
                title="growing community."
                description="join hundreds of surfers and photographers already using Swelter"
              />
            </SimpleGrid>
          </VStack>
        </Container>
      </Box>

      {/* Photographer Landing Page */}
      <Container maxW="container.xl" px={4} pb={40}>
        <Box ref={photographerRef}>
          <PhotographerLandingPage />
        </Box>
      </Container>
    </Box>
  );
};

export default LandingPage;
